import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Fremont({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-page-header"
        style={styles.forElement("page_header")}
      >
        <div className="formkit-container">
          <Elements.Content
            name="subheader"
            defaults={{
              content: "Our podcast is now live!"
            }}
          />
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{
              content:
                "Teaching everything we know to help you earn a living online."
            }}
          />
        </div>
      </div>
      <div
        className="formkit-content"
        style={styles.forElement("formkit-content")}
      >
        <div className="formkit-content-wrapper">
          <div className="formkit-sidebar">
            <Elements.Image
              className="formkit-image"
              name="image"
              size={{ h: 510, w: 510 }}
            />

            <div className="formkit-content-column">
              <Elements.Heading
                className="formkit-content-header"
                name="content_header"
                tag="h2"
                defaults={{ content: "Recent Episodes:" }}
              />
              <Elements.Region
                className="formkit-content-subheader"
                name="content_subheader"
              >
                <Elements.Content
                  defaults={{
                    content: "List your most recent episodes here."
                  }}
                />
              </Elements.Region>
            </div>
          </div>
          <div className="formkit-card" style={styles.forElement("card")}>
            <Elements.Heading
              className="formkit-form-header"
              name="form_header"
              tag="h2"
              defaults={{
                content: "Don't miss out on our future episodes!"
              }}
            />
            <Elements.Region
              className="formkit-form-subheader"
              name="form_subheader"
            >
              <Elements.Content
                defaults={{
                  content: "New exciting episodes every week."
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe to the show" }}
                />
              </Elements.CustomFields>
            </Elements.Form>

            <Elements.Content
              name="guarantee"
              defaults={{
                content: "We won't send you spam. Unsubscribe at any time."
              }}
            />
            <Elements.BuiltWith background="card" />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Fremont.style = "landing_page"
Fremont.categories = ["Podcast"]
Fremont.thumbnail = ""
Fremont.preview = "https://demo.ck.page/fremont"
Fremont.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Fremont, { name: "Fremont" })
